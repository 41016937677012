<template>
    <div class="search">
        <form @submit.prevent="search">
            <div class="field icon mb0">
                <input
                    v-model="model.search"
                    type="text"
                    class="sm"
                    :aria-label="$t('fields.search')"
                    :placeholder="$t('fields.search')"
                >
                <i class="icon md-magnify" />
            </div>
        </form>
    </div>
</template>

<script>

    export default {

        name: 'GridHeaderSearch',

        props: {
            value: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {
                    search: null,
                },
            };
        },

        watch: {
            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', this.cloneDeep(newVal));
                    }
                },
                deep     : true,
                immediate: true,
            },
        },

        methods: {

            /**
             * Emit filter data
             */
            search() {
                this.$emit('refresh', this.model);
            },

        },

    };

</script>
